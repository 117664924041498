<template>
  <v-dialog v-model="display" :width="width">
    <v-card rounded="0" class="px-12">
      <div class="d-flex align-center mb-4">
        <!-- <v-icon class="mr-2">mdi-qrcode</v-icon> -->
        <v-card-title class="word-break px-0 mx-0">Group QR Code</v-card-title>
      </div>
      <qrcode-vue v-if="url" :value="url" :size="250" level="H" />
      <p v-else-if="error" class="red--text">{{ error }}</p>
      <span v-if="url">{{ url }}</span>

      <v-card-actions class="pt-4">
        <v-spacer />
        <v-btn text color="brandCyan" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QrcodeVue from "qrcode.vue";

import { mapState } from "vuex";

export default {
  name: "QR",
  components: { QrcodeVue },
  props: {
    width: {
      type: Number,
      default: 500
    },
    clientId: {
      type: Number
    },
    groupId: {
      type: Number
    }
    // url: {
    //   type: String
    // }
  },
  data() {
    var d = {
      display: true,
      loading: false,
      url: null,
      error: null
    };
    return d;
  },
  created() {
    if (this.groupId) this.generateUrl();
  },

  methods: {
    close() {
      // if (!save)
      return this.reset();

      // this.loading = true;
      // const cropperRef = this.$refs.cropper;
      // const { canvas } = cropperRef.getResult();
      // canvas.toBlob(blob => {
      //   // Do something with blob: upload to a server, download and etc.
      //   const file = new File([blob], this.fileName);
      //   const imageUrl = canvas.toDataURL();
      //   this.newCompanyPicFile = file;
      //   this.newCompanyPicTemp = canvas.toDataURL();
      //   this.$emit("success", { file, imageUrl });
      //   this.loading = false;
      //   this.display = false;
      //   console.log("Successfully processed picture: ", file);
      //   // this.reset();
      // });
    },
    reset() {
      this.$emit("close");
      this.display = false;
    },
    generateUrl() {
      if (!this.groupId || !this.clientId) return;
      const client = this.clients.find(x => x.clientId == this.clientId);
      console.log({ client, clientId: this.clientId, groupId: this.groupId });
      if (client?.onboardingTokenPersistent) {
        this.url = `${window.location.origin}/signup/static/${client?.onboardingTokenPersistent}?groupId=${this.groupId}`;
      } else this.error = "Couldn't find a client with QR code groups set up";
    }
  },

  computed: {
    ...mapState(["clients", "userProfile"])
  },
  watch: {
    display: function(newVal) {
      if (!newVal) this.$emit("close");
    },
    groupId: function() {
      this.generateUrl();
    }
  }
};
</script>

<style></style>
