import { render, staticRenderFns } from "./Budgets.vue?vue&type=template&id=305d21c4&scoped=true"
import script from "./Budgets.vue?vue&type=script&lang=js"
export * from "./Budgets.vue?vue&type=script&lang=js"
import style0 from "./Budgets.vue?vue&type=style&index=0&id=305d21c4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "305d21c4",
  null
  
)

export default component.exports