<template>
  <div class="full-height">
    <v-card width="100%" rounded="0" class="full-height">
      <div id="header" class="d-flex justify-start" width="100%">
        <v-icon large dark class="pa-2 ml-3 my-2 brand-background border-radius"
          >mdi-account-multiple</v-icon
        >
        <v-card-title> {{ computedPageHeader }} </v-card-title>
      </div>
      <v-divider class="mx-4" />
      <v-row class="my-2 ml-3 mr-2">
        <v-col cols="12" sm="12" md="3">
          <div
            class="mb-4 mt-2 align-text-right d-flex flex-column align-end justify-center"
          >
            <div class="d-flex d-sm-none mb-3 align-self-center">
              <v-btn text color="primary" class="mx-1" to="/people"
                >People</v-btn
              >
              <div class="group-button">
                <v-btn
                  text
                  color="primary"
                  class="mx-1"
                  to="/people/groups"
                  v-if="permissions.includes('vue:read:groups')"
                  >Groups</v-btn
                >
              </div>
            </div>
            <v-autocomplete
              outlined
              dense
              class="pa-1 mb-2 full-width"
              hide-details
              :items="clients"
              item-text="formattedName"
              item-value="clientId"
              label="Client"
              v-model="clientId"
              @change="refreshClientId"
              v-if="userProfile.clientId === 1 && clients.length > 0"
            ></v-autocomplete>
            <div class="d-flex align-center">
              <span class="mr-3 mb-1" v-if="selected.length === 1"
                >{{ selected.length }} Person Selected</span
              >
              <span class="mr-3 mb-1" v-else
                >{{ selected.length }} People Selected</span
              >
              <v-btn
                v-if="selected.length"
                @click="clearSelected"
                rounded
                depressed
                small
                class="ml-1 mt-n1"
                >clear</v-btn
              >
            </div>
            <v-btn
              v-if="permissions.includes('users:create:user')"
              :disabled="!selectedUninvitedUsers.length"
              text
              small
              color="primary"
              @click="sendWhistleInviteEmail"
              >Send Whistle invite</v-btn
            >
            <v-btn
              v-if="permissions.includes('groups:create:group')"
              :disabled="selected.length === 0"
              text
              small
              color="primary"
              @click="showExistingGroupDialog = true"
              >Add to existing group</v-btn
            >
            <v-btn
              v-if="permissions.includes('groups:create:group')"
              :disabled="selected.length === 0"
              text
              small
              color="primary"
              @click="showNewGroupDialog = true"
              >Create a new group</v-btn
            >
            <v-btn
              v-if="
                permissions.includes('messages:create:message') && !isMobile
              "
              :disabled="selected.length === 0"
              text
              small
              color="primary"
              @click="loadCommunicationsDialog"
              >Send message</v-btn
            >
            <v-btn
              v-if="permissions.includes('users:delete:user')"
              :disabled="selected.length === 0"
              text
              small
              color="primary"
              @click="confirmDeleteMultiple = true"
              >Delete selected people</v-btn
            >

            <v-btn
              v-if="permissions.includes('users:delete:user')"
              text
              small
              color="primary"
              @click="showBulkDeleteDialog = true"
              >Bulk Delete via CSV</v-btn
            >

            <v-btn
              v-if="permissions.includes('users:create:export')"
              text
              small
              color="primary"
              @click="showUserExportDialog = true"
              >Export Users</v-btn
            >
          </div>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" sm="12" md="9">
          <div
            class="d-flex align-center"
            :class="isMobile ? '' : ' justify-space-between mr-2'"
          >
            <div class="d-none d-sm-flex mb-3 ">
              <v-btn text color="primary" class="mx-1" to="/people"
                >People</v-btn
              >
              <div class="group-button">
                <v-btn
                  text
                  color="primary"
                  class="mx-1"
                  to="/people/groups"
                  v-if="permissions.includes('vue:read:groups')"
                  >Groups</v-btn
                >
              </div>
            </div>
            <v-text-field
              outlined
              placeholder="Search"
              :prepend-icon="isMobile ? '' : 'mdi-magnify'"
              clearable
              single-line
              dense
              hide-details
              v-model="table.debounceSearch"
              class="shrink mb-3"
            ></v-text-field>
            <v-menu
              v-model="userFilterMenu"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="permissions.includes('users:create:user')"
                  text
                  color="primary"
                  class="mb-3"
                  v-bind="attrs"
                  v-on="on"
                  >Filter</v-btn
                >
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox
                      v-model="userFilterValue"
                      @change="updateFilter"
                      value="uninvited"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content class="text-left">
                    <v-list-item-title>Show uninvited people</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              color="brandCyan"
              class="white--text mb-3"
              depressed
              width="145"
              @click="loadUserForm"
              v-if="permissions.includes('users:create:user')"
              >Add People</v-btn
            >
          </div>
          <div>
            <v-data-table
              v-model="selected"
              :headers="peopleColumns"
              :items="visibleUsers"
              :options.sync="table.options"
              :server-items-length="table.total"
              item-key="userId"
              show-select
              :items-per-page="table.itemsPerPage"
              :footer-props="table.footerProps"
              class="people-table elevation-0"
              :class="{
                'cursor-pointer': permissions.includes('users:create:user')
              }"
              :loading="table.loading"
              :search="table.search"
              :sortable="false"
              loading-text="Loading... Please wait"
              no-data-text="No people found"
              no-results-text="No people found in search"
              @click:row="loadEditUserForm"
            >
              <template v-slot:[`item.profilePicUrl`]="{ item }">
                <div class="profile-pic-container">
                  <v-img
                    v-bind:src="item.profilePicUrl"
                    v-if="item && item.profilePicUrl"
                    height="35"
                    width="35"
                    max-height="35"
                    max-width="35"
                    class="circle-border-radius profile-picture ml-2"
                  />
                  <v-icon
                    v-else
                    class="profile-picture circle-border-radius icon-image ml-2"
                    >mdi-account</v-icon
                  >
                </div>
              </template>
              <template v-slot:[`item.tableDisplayName`]="{ item }">
                <span class="selectable-blue-text word-break">{{
                  item ? item.tableDisplayName : ""
                }}</span>
                <br />
                <span>{{
                  item ? item.businessEmail || item.businessPhone : ""
                }}</span>
              </template>
              <template v-slot:[`item.data-table-select`]="{ item }">
                <v-simple-checkbox
                  color="darkGrey"
                  class="py-0 my-0"
                  hide-details
                  :ripple="false"
                  @click.stop="addUserToSelected(item, false)"
                  :value="!!selected.find(x => item && x.userId == item.userId)"
                />
              </template>
              <template v-slot:[`item.externalUser`]="{ item }">
                <v-icon v-if="item.externalUser">mdi-check</v-icon>
              </template>
              <template v-slot:[`item.balance`]="{ item }">
                <v-btn
                  icon
                  @click.stop.native="
                    (balances.display = true),
                      (balances.userId = item.userId),
                      (balances.clientId = item.clientId)
                  "
                >
                  <v-icon>mdi-credit-card</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-navigation-drawer
      v-model="showUserDialog"
      temporary
      fixed
      right
      width="500"
    >
      <UserCreatorWidget
        v-if="showUserDialog"
        @close="closeUserNavDrawer"
        @get-users="getUsersV2(true)"
        :roles="roles"
        :clientId="clientId"
        :editingUser="editingUserForm"
        :editUserObject="newUser"
        source="PEOPLE"
      ></UserCreatorWidget>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-model="showBulkDeleteDialog"
      temporary
      fixed
      right
      width="500"
    >
      <UserDeletionWidget
        v-if="showBulkDeleteDialog"
        @close="showBulkDeleteDialog = false"
        @get-users="getUsersV2(true)"
        @reset-selected="selected = []"
        :roles="roles"
        :clientId="clientId"
        :editingUser="editingUserForm"
        :editUserObject="newUser"
        source="PEOPLE"
      ></UserDeletionWidget>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="showUserExportDialog"
      temporary
      fixed
      right
      width="500"
    >
      <CSVExport
        v-if="showUserExportDialog"
        @close="showUserExportDialog = false"
        table="UserClient"
      ></CSVExport>
    </v-navigation-drawer>
    <v-dialog v-model="confirmDeleteMultiple" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <!-- Error message to block a user from deleting themselves -->
          <v-card-title
            class="word-break align-text-left exit-warning-text"
            v-if="selected.map(x => x.userId).includes(userProfile.userId)"
          >
            Uh oh! Looks like you're trying to delete yourself
          </v-card-title>
          <v-card-title
            class="word-break align-text-left exit-warning-text"
            v-else-if="selected.length != 1"
          >
            Are you sure you want to delete the selected users?
          </v-card-title>
          <v-card-title
            class="word-break align-text-left exit-warning-text"
            v-else
          >
            Are you sure you want to delete the selected user?
          </v-card-title>
        </div>

        <v-card-actions class="mx-12">
          <v-btn
            color="primary"
            @click="confirmDeleteMultiple = false"
            outlined
            width="140"
            >{{
              selected.map(x => x.userId).includes(userProfile.userId)
                ? "Exit"
                : "Don't delete"
            }}</v-btn
          >
          <v-spacer />
          <v-btn
            color="primary"
            @click="deleteUsers"
            width="140"
            v-if="!selected.map(x => x.userId).includes(userProfile.userId)"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showNewGroupDialog" width="500" persistent>
      <v-card>
        <v-card-title class="dialog-header" color="primary">
          Create a new group
        </v-card-title>
        <v-divider />
        <v-container>
          <v-card-text>
            <v-form
              ref="newGroupForm"
              v-model="newGroupForm.valid"
              onSubmit="return false;"
            >
              <v-text-field
                class="pa-1"
                outlined
                v-model="newGroup.name"
                :rules="[
                  v => !!v || 'A group name is required',
                  v =>
                    !!(v && v.length < 255) ||
                    'The group name should be shorter'
                ]"
                label="Group name*"
                required
                @keyup.enter="upsertListGroup(true)"
              ></v-text-field>
            </v-form>
            <v-data-table
              v-model="selected"
              :headers="newGroupForm.groupColumns"
              :items="pruneSelectedForGroup"
              item-key="userId"
              class="elevation-1"
              :loading="newGroupForm.loadingGroupTable"
              loading-text="Loading... Please wait"
              no-data-text="No people found"
              :hide-default-footer="pruneSelectedForGroup.length <= 10"
            >
              <template v-slot:[`item.displayName`]="{ item }">
                <span class="word-break">{{
                  item ? item.tableDisplayName || item.displayName : ""
                }}</span>
              </template>
              <template v-slot:[`item.businessEmail`]="{ item }">
                <span class="word-break">
                  {{ item.businessEmail || item.businessPhone }}
                </span>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="resetGroupForm">
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="upsertListGroup(true)"
              :disabled="!newGroupForm.valid"
            >
              Add new group
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- Dialog used to add users to existing group -->
    <v-dialog v-model="showExistingGroupDialog" width="500" persistent>
      <v-card>
        <v-card-title class="dialog-header" color="primary">
          Add to existing group
        </v-card-title>
        <v-divider />
        <v-container>
          <v-card-text>
            <v-form ref="existingGroupForm" v-model="existingGroupForm.valid">
              <v-select
                class="pa-1"
                v-model="existingGroup.groupId"
                :items="listGroups"
                :loading="listGroupSelect.loading"
                item-text="groupName"
                item-value="groupId"
                :rules="[v => !!v || 'A group is required']"
                label="List Group"
                required
                no-data-text="No list groups found"
              >
                <template v-slot:append-item>
                  <v-divider
                    class="mb-2"
                    v-if="listGroupSelect.total > listGroups.length"
                  ></v-divider>
                  <v-list-item v-if="listGroupSelect.total > listGroups.length">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-btn
                          depressed
                          :disabled="listGroupSelect.loading"
                          @click="getGroupsV2(listGroupSelect.page + 1)"
                          >Load more +</v-btn
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-form>
            <v-data-table
              v-model="selected"
              :headers="newGroupForm.groupColumns"
              :items="pruneSelectedForGroup"
              item-key="userId"
              class="elevation-1"
              :loading="newGroupForm.loadingGroupTable"
              loading-text="Loading... Please wait"
              no-data-text="No people found"
              :hide-default-footer="pruneSelectedForGroup.length <= 10"
            >
              <template v-slot:[`item.displayName`]="{ item }">
                <span class="word-break">{{
                  item ? item.tableDisplayName || item.displayName : ""
                }}</span>
              </template>
              <template v-slot:[`item.businessEmail`]="{ item }">
                <span class="word-break">
                  {{ item.businessEmail || item.businessPhone }}
                </span>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="resetGroupForm">
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="upsertListGroup(false)"
              :disabled="!existingGroupForm.valid"
            >
              Add to group
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Balance dialog -->
    <CSMWalletManager
      v-if="balances.display"
      :key="balances.userId"
      :userId="balances.userId"
      :clientId="balances.clientId"
      @close="balances.display = false"
    />

    <!-- Dialog used for displaying loading message. -->
    <v-dialog
      v-model="dialogMessageCreator"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <MessageCreator
        v-if="dialogMessageCreator"
        @close-dialog="closeCommunicationsDialog"
        :trigger="communicationTrigger"
        peopleOrGroups="people"
        :largeVersion="true"
        :preSelectedAudience="selected"
      ></MessageCreator>
    </v-dialog>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="showLoadingDialog"
      :header="loadingHeaderMessage"
      :line1="loadingMessageLine1"
      :line2="loadingMessageLine2"
    ></LoadingDialog>
  </div>
</template>

<script>
import UserService from "@/services/UserService.js";
import WalletService from "@/services/WalletService.js";
import OnboardingService from "@/services/OnboardingService.js";
import GroupService from "@/services/GroupService.js";
import MarqetaService from "@/services/MarqetaService.js";

import LoadingDialog from "@/components/loadingDialog.vue";
import MessageCreator from "@/components/MessageCreator.vue";
import UserCreatorWidget from "@/components/UserCreatorWidget.vue";
import UserDeletionWidget from "@/components/UserDeletionWidget.vue";
import CSVExport from "@/components/people/CSVExport.vue";
import CSMWalletManager from "@/components/people/CSMWalletManager.vue";

import { debounce } from "@/shared_data/functions";

import { mapState } from "vuex";
import moment from "moment";
import currency from "currency.js";

export default {
  name: "People",
  title: "People | Whistle",
  components: {
    LoadingDialog,
    MessageCreator,
    UserCreatorWidget,
    UserDeletionWidget,
    CSVExport,
    CSMWalletManager
  },
  props: {
    showNewUserDialogOnLoad: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      anim: null,
      animationSpeed: 1,
      showLoadingDialog: false,
      loadingHeaderMessage: null,
      loadingMessageLine1: null,
      loadingMessageLine2: null,
      loadingUserTable: true,

      selected: [],
      peopleTableSearch: null,
      userFilterValue: null,
      userFilterMenu: false,
      allUsers: [],
      users: [],
      allGroups: [],
      groups: [],
      listGroups: [],
      roles: [],
      columns: [
        {
          text: " ",
          align: "center",
          sortable: false,
          value: "profilePicUrl",
          width: "0%"
        },
        { text: "Name", value: "tableDisplayName", width: "25%" },
        { text: "Organization", value: "organization", width: "30%" },
        { text: "Job Title", value: "jobTitle", width: "30%" },
        {
          text: "External",
          value: "externalUser",
          width: "10%",
          permission: "users:read:externalattribute",
          sortable: false
        },
        {
          text: "Wallet",
          value: "balance",
          width: "10%",
          permission: "users:read:balance-admin",
          sortable: false
        }
      ],
      showUserDialog: false,

      confirmDeleteMultiple: false,
      showNewGroupDialog: false,
      showBulkDeleteDialog: false,
      showUserExportDialog: false,

      editingUserForm: false,
      newUser: {
        clientId: null,
        userId: null,
        firstName: null,
        lastName: null,
        userIdAtClient: null,
        profilePicUrl: null,
        businessEmail: null,
        emailDomain: null,
        businessPhone: null,
        personalEmail: null,
        personalPhone: null,
        status: "Active",
        displayName: null,
        jobTitle: null,
        countryCode: null,

        birthday: null,
        roleStartDate: null,
        jobStartDate: null,
        businessAddress: null,
        businessCity: null,
        businessState: null,
        businessPostalCode: null,
        businessCountry: null,
        otherBusinessCountry: null,
        homeAddress: null,
        homeCity: null,
        homeState: null,
        homePostalCode: null,
        homeCountry: null,
        otherHomeCountry: null,
        organization: null,
        token1: null,
        token2: null,
        token3: null,

        roles: [],
        statusList: ["Active", "Inactive"]
      },
      newGroupForm: {
        valid: false,
        loadingGroupTable: false,
        groupColumns: [
          {
            text: "Name",
            align: "start",
            value: "displayName",
            width: "20%"
          },
          { text: "Email / Phone", value: "businessEmail", width: "20%" }
        ]
      },
      newGroup: {
        name: null
      },
      showExistingGroupDialog: false,
      existingGroupForm: {
        valid: false
      },
      existingGroup: {
        groupId: null
      },
      dialogMessageCreator: false,
      communicationTrigger: false,

      table: {
        itemsPerPage: 10,
        page: 1,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        users: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestSort: null
      },
      listGroupSelect: {
        loading: false,
        page: 0,
        total: 0,
        search: null,
        debounceSearch: null
      },
      balances: {
        display: false,
        userId: null,
        clientId: null
      }
    };
  },
  created() {
    if (this.$auth) {
      // this.clientId = this.userProfile.clientId;
      this.getUsersV2(true);
      // this.getGroups();
      this.getGroupsV2(0);
      if (this.permissions.includes("users:read:role")) this.getRoles();
      if (this.showNewUserDialogOnLoad) this.loadUserForm();
    }
  },
  mounted() {
    //Move the fresh works help widget
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "-55px";
      freshworks.style["max-width"] = freshworks.style["min-width"] = "90px";
    }
  },
  destroyed() {
    var freshworks = document.querySelector("#launcher-frame");
    if (freshworks) {
      freshworks.style.right = "22px";
    }
  },
  methods: {
    async getUsersV2(reset = false) {
      let v1 = false; //process.env.VUE_APP_ENVIRONMENT !== "dev";
      try {
        this.table.loading = true;
        if (reset) {
          this.table.options.page = 1;
        }

        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        let options = this.table.options;
        let page = this.table.options.page;
        // let search = this.table.search;
        let search = this.table.search
          ? this.table.search.replace(/'/g, "\\'")
          : null;

        let filter = `status != 'Deleted' && clientId=${this.clientId}`;
        let filterValue = this.userFilterValue;
        if (filterValue == "uninvited")
          //   filter += ` && onboardingEmailSent = 0 && passwordEnabled = 1 && businessEmail != null`;
          // Adds support for SMS invites
          filter += ` && ((onboardingEmailSent = 0 && passwordEnabled = 1 && businessEmail != null) || (onboardingTextSent = 0 && smsPasswordlessEnabled = 1 && businessPhone != null && passwordEnabled = 0))`;
        if (search) {
          // If search we need to search for every field that's in the table
          filter += ` && (businessEmail.like('%${search}%') || businessPhone.like('%${search}%') || firstName.like('%${search}%') || lastName.like('%${search}%') || displayName.like('%${search}%') || organization.like('%${search}%') || jobTitle.like('%${search}%') || userId.like('%${search}') || userId.like('${search}%')`;
          // // We also split the search to get the first and last word for attempted first / last name match
          var searchSplit = search.split(" ");
          if (searchSplit.length > 1) {
            var searchFirst = searchSplit[0];
            var searchLast = searchSplit[searchSplit.length - 1];

            filter += `|| (firstName.like('%${searchFirst}%') && lastName.like('%${searchLast}%'))`;
          }

          filter += ")";
        }
        // if first page, fetch double
        let limit = options.itemsPerPage; //page == 1 ? options.itemsPerPage * 2 : options.itemsPerPage;
        let offset = reset ? 0 : (page - 1) * limit;
        if (this.table.options.sortBy && options.sortBy.length > 0) {
          let column = options.sortBy;
          let type =
            options.sortDesc &&
            options.sortDesc.length > 0 &&
            options.sortDesc[0] === true
              ? "DESC"
              : "ASC";

          // If displayname then we really check display name, first/last name
          if (column == "tableDisplayName")
            sort = `${v1 ? "" : "User."}firstName ${type}, ${
              v1 ? "" : "User."
            }lastName ${type}`;
          else sort = column + " " + type;
        } else
          var sort = `${v1 ? "" : "User."}firstName ASC, ${
            v1 ? "" : "User."
          }lastName ASC`;
        // let expand = "LoginHistory";
        console.log("Sort ", sort);
        console.log("Filter ", filter);
        this.table.latestSort = sort;
        let params = {
          filter: v1 ? filter : undefined,
          limit,
          offset,
          sort,
          clientId: this.clientId,
          screen: v1 ? undefined : "people",
          search: this.table.search,
          uninvited: this.userFilterValue == "uninvited" ? 1 : 0
          // expand: expand,
        };
        let response = await UserService.getUsersV2(params);

        // if (options != this.table.awaitingPage || search != this.table.search)
        //   return;
        // we have to compare states to see if we ignore results (only if them spam a filter)
        if (
          this.userFilterValue != filterValue ||
          sort != this.table.latestSort ||
          this.clientId != params.clientId ||
          this.table.search != params.search ||
          limit != this.table.options.itemsPerPage
        ) {
          console.log("Ignoring response", {
            filter: this.userFilterValue != filterValue,
            sort: sort != this.table.latestSort,
            clientId: this.clientId != params.clientId,
            search: this.table.search != params.search
          });
          return;
        }

        console.log("User response ", response);
        let rows = response.result.rows;

        this.table.total = response.result.count;

        // We instantiate the users array with an empty array to match the length of the results
        if (reset)
          this.table.users = new Array(this.table.total).fill(undefined);
        //Prune the returned users for the data we need for the table
        rows = rows.map(function(user) {
          // if (!user.lastLogin) {
          //   var lastActive = "Never";
          // } else {
          //   lastActive = moment(user.lastLogin).fromNow();
          // }
          // user.lastActive = lastActive;
          user.tableDisplayName = user.firstName + " " + user.lastName;
          user.id = user.userId;
          user.name = user.displayName || user.firstName + " " + user.lastName;
          return user;
        });

        // If at the beginning we just grab the first results and then append the rest of the empty array
        // If not the beginning, we append the existing fetched array, then the new results, and anything after
        // this.table.users = rows;
        if (offset == 0)
          this.table.users = rows.concat(this.table.users.slice(rows.length));
        else
          this.table.users = this.table.users
            .slice(0, offset)
            .concat(rows, this.table.users.slice(offset + rows.length));

        // if (reset) this.selected = [];
        // this.$store.dispatch("setSearchArray", usersArray);
        this.table.loading = false;
      } catch (err) {
        console.log("Error getting users ", err);
        this.table.loading = false;
      }
    },
    getRoles() {
      UserService.getRoles()
        .then(response => {
          console.log("Roles Response: ", response);

          // Now adding logic to remove Whistle-roles if clientId is not 1
          // if (this.userProfile.clientId != 1) {
          //   response = response.filter((x) => !x.name.startsWith("1"));
          // }

          this.roles = response;
        })
        .catch(error => {
          console.log("Error!" + error);
        });
    },
    async getGroupsV2(page) {
      try {
        this.listGroupSelect.loading = true;
        // if (reset) {
        //   this.listGroupSelect.page = 1;
        // }
        this.listGroupSelect.page = page;
        let search = this.listGroupSelect.search;

        let filter = `status != 'Deleted' && clientId=${this.clientId} && groupType = 'List'`;
        if (search) {
          // If search, we need to search for every field that's in the table
          filter += ` && (groupDisplayName.like('%${search}%') || groupName.like('%${search}%'))`;
        }
        // If first page, fetch double
        // let limit = page == 1 ? options.itemsPerPage * 2 : options.itemsPerPage;
        let limit = 20;
        let offset = page * limit;
        let sort = "groupId DESC";
        console.log({
          page: page,
          limit: limit,
          sort: sort,
          filter: filter,
          offset: offset
        });
        let response = await GroupService.getGroupsV2({
          filter: filter,
          limit: limit,
          offset: offset,
          sort: sort,
          expand: "GroupAssignment.User"
        });
        console.log("Current page ", page);
        console.log("Group response ", response);
        let rows = response.result.rows;
        this.listGroupSelect.total = response.result.count;
        if (page == 0) this.listGroups = rows;
        else this.listGroups = this.listGroups.concat(rows);

        // if (reset) this.table.selected = [];

        this.listGroupSelect.loading = false;
      } catch (error) {
        console.log("Error getting list groups", error);
        this.listGroupSelect.loading = false;
      }
    },
    loadUserForm() {
      this.editingUserForm = false;
      this.showUserDialog = true;
    },
    async upsertListGroup(newGroup = false) {
      // If they hit enter with an invalid form, then we ignore for now
      if (!this.newGroupForm.valid && newGroup === true) return;
      //This function grabs anyone currently assigned to the chosen group and appends anyone selected to it
      const createGroup = !!this.showNewGroupDialog;
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = `${
        createGroup ? "Creating" : "Updating"
      } the ${createGroup ? "new" : "chosen"} group`;
      this.loadingMessageLine1 =
        "You'll just need to wait a sec, this shouldn't take long!";
      this.loadingMessageLine2 = "";

      try {
        const existingGroup = this.listGroups.find(
          x => x.groupId === this.existingGroup.groupId
        );
        if (!existingGroup && !createGroup) throw "No group found";
        const existingUsers =
          !createGroup && existingGroup.GroupAssignments
            ? existingGroup.GroupAssignments.map(x => x.userId)
            : [];

        let group = {
          clientId: this.clientId,
          groupId: createGroup ? undefined : existingGroup.groupId,
          groupName: createGroup
            ? this.newGroup.name
            : existingGroup.groupDisplayName,
          groupDisplayName: createGroup
            ? this.newGroup.name
            : existingGroup.groupDisplayName,
          source: "PEOPLE",
          userIds: [existingUsers, this.selected.map(x => x.userId)].flat()
        };

        console.log("Group we're going to create/update ", group);

        let res = await GroupService.createGroupV2(group, "list");
        console.log("Got list group response ", res);
        this.resetGroupForm();
        this.getGroupsV2(0);
        this.selected = [];
      } catch (err) {
        console.log("Error creating/updating list group ", err);
      } finally {
        this.showLoadingDialog = false;
      }
    },
    resetGroupForm() {
      if (this.$refs.newGroupForm) this.$refs.newGroupForm.reset();
      if (this.$refs.existingGroupForm) this.$refs.existingGroupForm.reset();
      this.showNewGroupDialog = false;
      this.showExistingGroupDialog = false;
    },
    async loadEditUserForm(row) {
      if (!this.permissions.includes("users:create:user")) {
        return;
      }
      var userId = row.userId;
      var user = this.table.users.find(x => x.userId === userId);
      this.newUser.originalBusinessEmail = user.businessEmail;
      // var index = user.businessEmail.indexOf("@");
      // var emailPrefix = user.businessEmail.substring(0, index);
      // var emailDomain = user.businessEmail.substring(index + 1);
      this.newUser.clientId = user.clientId;
      this.newUser.userId = userId;
      this.newUser.firstName = user.firstName;
      this.newUser.lastName = user.lastName;
      this.newUser.userIdAtClient = user.userIdAtClient;
      this.newUser.businessEmail = user.businessEmail;
      // this.newUser.emailDomain = emailDomain;
      this.newUser.businessPhone = user.businessPhone;
      this.newUser.personalEmail = user.personalEmail;
      this.newUser.personalPhone = user.personalPhone;
      this.newUser.status = user.status;
      this.newUser.displayName = user.displayName;
      this.newUser.jobTitle = user.jobTitle;
      this.newUser.countryCode = user.countryCode;
      this.newUser.startDate = user.startDate;
      this.newUser.profilePicUrl = user.profilePicUrl;
      // this.newUser.lastActive = user.lastActive;
      this.newUser.firstLogin = user.firstLogin;
      this.newUser.birthday = user.birthday;
      this.newUser.roleStartDate = user.roleStartDate;
      this.newUser.jobStartDate = user.jobStartDate;
      this.newUser.businessAddress = user.businessAddress;
      this.newUser.businessCity = user.businessCity;
      this.newUser.businessState = user.businessState;
      this.newUser.businessPostalCode = user.businessPostalCode;
      console.log(user.businessCountry);
      this.newUser.businessCountry = null;
      if (user.businessCountry === null) {
        this.newUser.businessCountry = null;
        this.newUser.otherBusinessCountry = null;
      } else if (
        user.businessCountry !== "US" &&
        user.businessCountry !== "GB"
      ) {
        this.newUser.businessCountry = "00";
        this.newUser.otherBusinessCountry = user.businessCountry;
      } else {
        this.newUser.businessCountry = user.businessCountry;
      }
      this.newUser.homeAddress = user.homeAddress;
      this.newUser.homeCity = user.homeCity;
      this.newUser.homeState = user.homeState;
      this.newUser.homePostalCode = user.homePostalCode;
      console.log(user.homeCountry);
      if (user.homeCountry === null) {
        this.newUser.homeCountry = null;
        this.newUser.otherHomeCountry = null;
      } else if (user.homeCountry !== "US" && user.homeCountry !== "GB") {
        this.newUser.homeCountry = "00";
        this.newUser.otherHomeCountry = user.homeCountry;
      } else {
        this.newUser.homeCountry = user.homeCountry;
      }
      this.newUser.organization = user.organization;
      this.newUser.annualPaymentCap = user.annualPaymentCap;
      this.newUser.externalUser = user.externalUser;
      this.newUser.primaryClientId = user.primaryClientId;

      this.newUser.token1 = user.token1;
      this.newUser.token2 = user.token2;
      this.newUser.token3 = user.token3;

      this.newUser.passwordEnabled = user.passwordEnabled;
      this.newUser.emailPasswordlessEnabled = user.emailPasswordlessEnabled;
      this.newUser.smsPasswordlessEnabled = user.smsPasswordlessEnabled;

      this.newUser.onboardingEmailSent = user.onboardingEmailSent;
      this.newUser.onboardingTextSent = user.onboardingTextSent;
      if (!user.onboardingEmailSent) {
        // Converts UTC back to local time to prefil into the timestamp selector
        if (!user.scheduledOnboardingDate)
          this.newUser.scheduledOnboardingDate = user.scheduledOnboardingDate;
        else {
          console.log(user.scheduledOnboardingDate);
          this.newUser.scheduledOnboardingDate = moment(
            moment.utc(user.scheduledOnboardingDate).toDate()
          )
            .local()
            .format("YYYY-MM-DDTHH:mm");
        }
      }

      // var userRoles = await UserService.getRolesByUser(user.userId).catch(
      //   () => {
      //     return [];
      //   }
      // );
      // // Remove any that start with 9
      // this.newUser.roles = userRoles
      // .map(x => x.id);

      this.editingUserForm = true;
      this.showUserDialog = true;
    },

    deleteUsers() {
      //We have one endpoint to delete a single user, so we create a loop to call that endpoint for every user.
      this.showLoadingDialog = true;
      this.loadingHeaderMessage = "Deleting selected people";
      this.loadingMessageLine1 = "Relax while we do the hard part!";
      this.loadingMessageLine2 =
        "Feel free to close this page. We'll email you when we're done";
      //we call the loop because the button is disabled if no one is selected, so we assume the array is not empty
      //The context changes within the function so we have to define 'this'
      var users = this.selected.map(x => x.userId);

      UserService.deleteUsers(users, { clientId: this.clientId })
        .then(response => {
          console.log(response);
          if (response && response.data && response.data.success)
            console.log("Users deleted");
          else console.log("Error deleting user");

          this.showLoadingDialog = false;
          this.getUsersV2(true);
          this.confirmDeleteMultiple = false;
          this.selected = [];
        })
        .catch(error => {
          console.log(error);
          this.showLoadingDialog = false;
          this.confirmDeleteMultiple = false;
        });
    },
    loadCommunicationsDialog() {
      this.communicationTrigger = !this.communicationTrigger;
      this.dialogMessageCreator = true;
    },
    closeCommunicationsDialog() {
      this.dialogMessageCreator = false;
    },
    closeUserNavDrawer(clear) {
      this.editingUserForm = false;
      if (clear === true) this.selected = [];
      this.showUserDialog = false;
    },

    refreshClientId() {
      this.selected = [];
      this.table.users = [];
      this.getUsersV2(true);
      this.getGroupsV2(0);
    },
    openFAQ() {
      // Opens up FreskDesk in a new tab
      window.open("https://whistle.freshdesk.com/support/home", "_blank");
    },
    updateFilter() {
      // this.selected = [];
      this.getUsersV2(true);
    },
    clearSelected() {
      this.selected = [];
    },
    sendWhistleInviteEmail() {
      this.loadingHeaderMessage = "Sending invitations";
      this.loadingMessageLine1 = "Please wait";
      this.loadingMessageLine2 = "This should be pretty quick!";
      this.showLoadingDialog = true;
      var users = this.selectedUninvitedUsers.map(x => x.userId);
      OnboardingService.sendWhistleInviteEmails(users, this.clientId)
        .then(response => {
          console.log(
            "Response from sending onboarding invite emails: ",
            response
          );
          this.showLoadingDialog = false;
          this.selected = [];
          this.getUsersV2(true);
        })
        .catch(error => {
          console.log("Error!", error);
          this.showLoadingDialog = false;
        });
    },
    addUserToSelected(user) {
      // If they're adding the same group twice, we want to replace
      // This is because when loadBuilder is called, we temporarily put ProgramGroups as the audience
      // But they don't have a name. So once getGroups returns, we replace with groups with names
      console.log("Add user to selected");
      let existingUserIndex = this.selected.findIndex(
        x => x.userId == user.userId
      );
      if (existingUserIndex != -1) this.selected.splice(existingUserIndex, 1);
      else this.selected.push(user);

      // Then we find the group in the main array and mark as display = false;
      // var groupIndex = this.data.groups.findIndex(
      //   x => x.groupId == group.groupId
      // );
      // if (groupIndex !== -1) this.data.groups[groupIndex].display = false;
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "clients",
      "postItArray",
      "permissions",
      "globalClientId"
    ]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    pruneSelectedForGroup() {
      var usersPruned = this.selected.map(function(row) {
        return {
          displayName: row.tableDisplayName,
          businessEmail: row.businessEmail,
          businessPhone: row.businessPhone
        };
      });
      return usersPruned;
    },
    computedPageHeader() {
      var title = "";
      if (this.permissions.includes("vue:read:people")) {
        title = title.concat("People");
      }
      if (this.permissions.includes("vue:read:groups")) {
        if (this.permissions.includes("vue:read:people")) {
          title = title.concat(" and ");
        }
        title = title.concat("Groups");
      }
      return title;
    },
    visibleUsers() {
      // Return chunk of users in array based on page
      var startingIndex = this.table.options.page - 1 || 0;
      let itemsPerPage =
        (this.table.options && this.table.options.itemsPerPage) ||
        this.table.itemsPerPage;
      return this.table.users
        .slice()
        .slice(
          startingIndex * itemsPerPage,
          startingIndex * itemsPerPage + itemsPerPage
        )
        .filter(x => x !== undefined);
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    peopleColumns() {
      // If on mobile then we declutter and just show the name/email
      return this.isMobile
        ? [{ ...this.columns[1] }]
        : this.columns.filter(
            x => !x.permission || this.permissions.includes(x.permission)
          );
    },
    selectedUninvitedUsers() {
      return this.selected.filter(
        x =>
          (!x.onboardingEmailSent && x.passwordEnabled && x.businessEmail) ||
          (!x.passwordEnabled &&
            x.smsPasswordlessEnabled &&
            !x.onboardingTextSent &&
            x.businessPhone &&
            !x.externalUser)
      );
    }
  },
  watch: {
    // This watches any options configured for the data table so we know if the user interacts and changes the sorting
    "table.options": {
      handler(newVal, oldVal) {
        console.log("New options ", newVal);
        console.log("Old options ", oldVal);
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        var apiCall = true;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }
        // This means that they just hit the next button. We have to check what the max page we've loaded in is
        // We can optimize and load in existing data
        if (
          !reset &&
          oldVal.page !== newVal.page &&
          this.table.users.filter(x => x !== undefined).length >
            (newVal.page - 1) * newVal.itemsPerPage
        ) {
          console.log("We're not going to make the API call");
          apiCall = false;
        }
        if (apiCall) this.getUsersV2(reset);
      },
      deep: true
    },
    "table.debounceSearch": debounce(function(newVal) {
      this.table.search = newVal;
      this.getUsersV2(true);
    }, 500)
  }
};
</script>

<style scoped>
.tabs {
  margin-top: 12px;
}

.tabs .theme--dark.v-btn--active::before {
  opacity: 0;
}

.topic-left-enter {
  opacity: 0;
  transform: translateX(100%);
}

.topic-right-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.topic-left-enter-active,
.topic-right-enter-active {
  transition: all 0.65s cubic-bezier(0.19, 1, 0.22, 1);
}

.brand-background {
  background-color: var(--v-brand-base);
}
.border-radius {
  border-radius: 5px;
}
.group-button .v-btn--active.no-active::before {
  opacity: 0 !important;
}
.profile-picture {
  border: 2px solid;
  border-color: var(--v-grey-base);
}

.icon-image {
  height: 35px;
  width: 35px;
}

.people-table {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}

/* 
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row,
.v-data-table__mobile-row,
td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  background: blue !important;
  min-height: 2px;
}

.people-table .v-data-table__mobile-row,
.people-table table tr td {
  flex-direction: row-reverse !important;
}

.v-application--is-ltr .v-data-table__mobile-row__cell {
  text-align: left !important;
}

.profile-pic-contaier {
  position: absolute;
  left: 50px;
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 2px;
} */

.people-table >>> .v-data-table-header {
  background-color: white;
}

.ellipsis-fill {
  flex-shrink: 0 !important;
  flex-grow: 1 !important;
  border-bottom: dotted black 1px;
  transform: translateY(-7px);
}
</style>
