<template>
  <v-app>
    <v-dialog v-model="showLoginError" width="450"
      ><LoginErrorPopup v-if="showLoginError"
    /></v-dialog>
    <v-dialog v-model="showMagicLinkWarningModal" persistent width="450"
      ><MagicLinkExpirationPopup v-if="showMagicLinkWarningModal"
    /></v-dialog>

    <div v-if="failedLogin || showClientBlacklist" class="gradient-background">
      <v-row justify="center"
        ><v-col cols="11" sm="11" md="5" lg="5" xl="5">
          <v-card class="content py-6" width="100%" elevation="0">
            <div class="d-flex align-center justify-space-between">
              <Robin
                v-if="!$vuetify.breakpoint.xs"
                animation="swayandbounce"
                :showText="false"
                :loop="true"
                :width="100"
                :height="100"
              />
              <div
                class="mt-4 mx-auto word-break"
                :class="{
                  'text-left': !$vuetify.breakpoint.xs
                }"
              >
                <p
                  class="px-2 word-break grey--text"
                  :class="{
                    'text-md': isMobile || $vuetify.breakpoint.md,
                    'text-lg': $vuetify.breakpoint.lg,
                    'text-xl': $vuetify.breakpoint.xl
                  }"
                >
                  Uh oh, we had trouble loading your account. Please try logging
                  in or refreshing the page.
                </p>

                <!-- <v-slide-y-transition>
            <span v-show="show">This should only take a few seconds.</span>
          </v-slide-y-transition> -->
              </div>
            </div>
            <p style="color: grey" class="mb-2">
              If this continues, please contact us:
            </p>
            <p class="" style="color: grey">
              Email -
              <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
            </p>
            <p class="" style="color: grey">
              Phone (Toll Free) - <b class="black--text">(855) 264-3329</b>
            </p>
            <v-btn
              color="brandCyan"
              @click="logout"
              depressed
              width="120"
              class="mt-4 white--text mx-2"
              >Logout</v-btn
            >
            <v-btn
              color="brandCyan"
              @click="refresh"
              depressed
              width="120"
              class="mt-4 white--text mx-2"
              >Refresh</v-btn
            >
          </v-card></v-col
        ></v-row
      >
      <v-row
        ><span v-if="failedLogin"
          >[{{ new Date() }}, loading: {{ $auth.loading ? "y" : "n" }}, status:
          {{ $auth.step || "no val" }}, auth:
          {{ $auth.isAuthenticated ? "y" : "n" }}, auth err:
          {{ $auth.error ? $auth.error : "none" }}, err:
          {{ errorString ? errorString : "none" }}
          <span v-if="$auth.isAuthenticated"
            >, user: {{ $auth.user.sub }}, email:
            {{
              userProfile && userProfile.businessEmail
                ? userProfile.businessEmail
                : userProfile && userProfile.businessPhone
                ? userProfile.businessPhone
                : "none"
            }}, token: {{ token ? token.length : "n" }}, perm:
            {{ permissions ? permissions.length : "n" }}, fail:
            {{ failedLogin ? "y" : "n" }}</span
          >]</span
        ></v-row
      >
    </div>
    <div class="fill-height" v-else-if="showCardActivationSuccess">
      <MarqetaCardActivationSuccess
        class="fill-height"
      ></MarqetaCardActivationSuccess>
    </div>
    <div class="fill-height" v-else-if="showCardPINSuccess">
      <MarqetaCardPINSuccess class="fill-height"></MarqetaCardPINSuccess>
    </div>
    <div class="fill-height" v-else-if="showHostedVirtualCard">
      <MarqetaVirtualCardHosted class="fill-height"></MarqetaVirtualCardHosted>
    </div>
    <div class="fill-height" v-else-if="showHostedActivateCard">
      <MarqetaActivateCardHosted
        class="fill-height"
      ></MarqetaActivateCardHosted>
    </div>
    <div class="fill-height" v-else-if="showHostedCardPIN">
      <MarqetaCreatePINHosted class="fill-height"></MarqetaCreatePINHosted>
    </div>
    <div class="fill-height" v-else-if="showMagicLinkLogin">
      <LoginMagicLink class="fill-height"></LoginMagicLink>
    </div>
    <div class="fill-height" v-else-if="showLogin">
      <Login class="fill-height"></Login>
    </div>
    <div
      v-else-if="loading"
      class="gradient-background d-flex flex-column justify-space-between white--text"
    >
      <div class="d-flex align-start justify-center">
        <div
          class="content d-flex flex-row align-center justify-center flex-wrap"
        >
          <lottie
            :options="lottieOptions"
            :height="140"
            :width="140"
            v-on:animCreated="handleAnimation"
          />
          <div
            class="text mt-4 mx-auto white--text"
            :class="{
              'text-left': !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm
            }"
          >
            <span>Loading, please wait</span>
            <br />
            <v-slide-y-transition>
              <span v-show="show">This should only take a few seconds.</span>
            </v-slide-y-transition>
          </div>
        </div>
      </div>
      <span
        >[{{ new Date() }}, loading: {{ $auth.loading ? "y" : "n" }}, status:
        {{ $auth.step || "no val" }}, auth:
        {{ $auth.isAuthenticated ? "y" : "n" }}, auth err:
        {{ $auth.error ? $auth.error : "none" }}, err:
        {{ errorString ? errorString : "none" }}
        <span v-if="$auth.isAuthenticated"
          >, user: {{ $auth.user.sub }}, email:
          {{
            userProfile && userProfile.businessEmail
              ? userProfile.businessEmail
              : userProfile && userProfile.businessPhone
              ? userProfile.businessPhone
              : "none"
          }}, token: {{ token ? token.length : "n" }}, perm:
          {{ permissions ? permissions.length : "n" }}, fail:
          {{ failedLogin ? "y" : "n" }}</span
        >]</span
      >
    </div>
    <!-- If the user is trying to go to unsubscribe -->
    <!-- <div class="fill-height" v-else-if="unsubscribing">
      <EmailUnsubscribe class="fill-height"></EmailUnsubscribe>
    </div> -->
    <!-- <div class="fill-height" v-else-if="publicSurvey">
      <SurveysPublic class="fill-height"></SurveysPublic>
    </div> -->
    <div
      class="fill-height"
      v-else-if="
        $route.name == 'user-signup' || $route.name == 'user-signup-static'
      "
    >
      <Activation class="fill-height"></Activation>
    </div>
    <!-- We only want this to show once we've actually processed that the user isn't logged in, loaded, and trying to route to setup -->
    <!-- <div class="fill-height" v-else-if="showClientSetupUnauthenticated">
      <ClientActivation class="fill-height"></ClientActivation>
    </div> -->

    <!-- If the user is not logged in, show login screen -->
    <!-- <div class="fill-height" v-else-if="!$auth.isAuthenticated && loaded">
      <Home class="fill-height"></Home>
    </div>
    <div
      v-else-if="!$auth.isAuthenticated && !loaded"
      class="gradient-background d-flex align-start justify-center"
    >
      <div
        class="content d-flex flex-row align-center justify-space-between flex-wrap"
      >
        <lottie
          :options="lottieOptions"
          :height="140"
          :width="140"
          v-on:animCreated="handleAnimation"
        />
        <div
          class="text mt-4 mx-auto"
          :class="{
            'text-left': !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm,
          }"
        >
          <span>Loading, please wait</span>
          <br />
          <v-slide-y-transition>
            <span v-show="show">This should only take a few seconds.</span>
          </v-slide-y-transition>
        </div>
      </div>
    </div> -->
    <!-- Otherwise, user is considered logged in -->
    <div class="fill-height" v-else>
      <!-- If the user profile has loaded, then show main content -->
      <div class="fill-height">
        <div class="fill-height" v-if="showClientSetup">
          <ClientActivation class="fill-height"></ClientActivation>
        </div>
        <!-- Show payments version of activation based on computed property -->
        <div v-else-if="showPaymentsActivation" class="fill-height">
          <PaymentsActivation class="fill-height"></PaymentsActivation>
        </div>
        <!-- If this is the user's first time, activation screen -->
        <div
          v-else-if="userProfile && userProfile.firstLogin"
          class="fill-height"
        >
          <Activation class="fill-height"></Activation>
        </div>
        <!-- Otherwise, show main screen -->
        <div v-else class="fill-height">
          <navBar
            v-if="displayNavigationBar"
            @wallet-menu="walletToggler"
            @toggle-profile="toggleProfile"
            @toggle-nav="toggleMobileNav"
          ></navBar>

          <div id="app" class="full-height full-width">
            <!-- Left nav only visible on non-mobile -->
            <v-navigation-drawer
              v-if="
                !$vuetify.breakpoint.sm &&
                  !$vuetify.breakpoint.xs &&
                  displayNavigationDrawer
              "
              app
              permanent
              clipped
              expand-on-hover
              update:mini-variant="expanded"
              class="hidden-sm-and-down"
            >
              <navDrawer @show-mobile="showMobileModal = true"></navDrawer>
            </v-navigation-drawer>
            <!-- Nav drawer only visible on mobile - toggled by the menu button that appears on mobile -->
            <v-navigation-drawer
              v-model="showMobileNavDrawer"
              temporary
              fixed
              class="d-sm-flex d-md-none"
            >
              <navDrawer @show-mobile="showMobileModal = true"></navDrawer>
            </v-navigation-drawer>
            <!-- If the user is on mobile, show the popup with links -->
            <v-dialog v-model="showMobileModal" width="450"
              ><AppStorePopup @close="closeAppStorePopup"
            /></v-dialog>
            <v-main
              class="full-height"
              :style="
                !$vuetify.breakpoint.sm &&
                !$vuetify.breakpoint.xs &&
                displayNavigationDrawer
                  ? 'padding-left: 55px;'
                  : ''
              "
            >
              <div
                :class="{
                  'main-content-top': displayNavigationDrawer,
                  'main-content-sides': displayNavigationDrawer && !isMobile
                }"
                class="full-height"
              >
                <help-widget v-bind="help"></help-widget>
                <router-view />
              </div>
            </v-main>
          </div>
        </div>

        <!-- Begin wallet modal -->
        <v-navigation-drawer
          v-if="displayWalletMenu"
          v-model="displayWalletMenu"
          class="wallet-nav"
          ref="walletNavDrawer"
          temporary
          fixed
          right
          width="500"
          :stateless="
            walletMenuScreen == 'display-virtual-instruction' ||
              walletMenuScreen == 'display-add-to-mobile' ||
              (walletMenuScreen == 'donate' && onMobile)
          "
        >
          <v-card elevation="0" rounded="0" v-if="walletMenuScreen == 'main'">
            <v-card-title class="dialog-header" color="brandCyan">
              <v-row class="" align="center">
                <v-col
                  class="my-0 py-0 word-break text-left"
                  cols="8"
                  sm="8"
                  md="8"
                >
                  {{ userProfile.firstName + "'s'" }} Wallet
                </v-col>
                <v-col
                  class="my-0 py-0 text-right cursor-pointer"
                  cols="4"
                  sm="4"
                  md="4"
                >
                  <span @click="closeWallet"
                    >Close <v-icon color="white">mdi-close</v-icon></span
                  >
                </v-col>
              </v-row>
            </v-card-title>
            <v-row no-gutters class="mb-3">
              <MarqetaWidgetManager
                class="pa-2"
                ref="marqeta-widget"
                :isModal="true"
                @close-wallet="closeWallet"
                @scroll-top="scrollToTopOfWalletDrawer"
              />
            </v-row>
          </v-card>

          <v-card
            elevation="0"
            rounded="0"
            v-else-if="walletMenuScreen == 'display-virtual-instruction'"
          >
            <v-card-title class="dialog-header" color="primary">
              <v-row class="">
                <v-col class="my-0 py-0 text-left" cols="6" sm="6" md="6">
                  Spend it online
                </v-col>
                <v-col
                  class="my-0 py-0 text-right cursor-pointer"
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <span @click="closeWallet"
                    >Close <v-icon color="white">mdi-close</v-icon></span
                  >
                </v-col>
              </v-row>
            </v-card-title>
            <v-stepper vertical class="elevation-0" v-model="defaultStepper">
              <v-stepper-step
                :complete="true"
                step="1"
                complete-icon="mdi-numeric-1"
              >
                Copy your card number on the left by clicking the copy symbol
              </v-stepper-step>
              <v-stepper-content step="1"></v-stepper-content>
              <!-- <v-stepper-content step="1">
                <v-chip v-ripple outlined color="primary" label class="mr-4">
                  <v-avatar>
                    <v-icon class="mr-1">mdi-download</v-icon>
                  </v-avatar>
                  Download Template
                </v-chip> 
              </v-stepper-content> -->
              <v-stepper-step
                :complete="true"
                step="2"
                complete-icon="mdi-numeric-2"
              >
                Navigate to your favorite online shopping site in a new tab
                (we’ve listed some popular ones below)
              </v-stepper-step>
              <v-stepper-content step="0" class="text-left">
                <small
                  ><a href="http://amazon.com" target="_blank">Amazon.com</a
                  ><br />
                  <a href="http://ebay.com" target="_blank">eBay.com</a><br />
                  <a href="http://target.com" target="_blank"
                    >Target.com</a
                  ></small
                >
              </v-stepper-content>

              <v-stepper-step
                :complete="true"
                step="3"
                complete-icon="mdi-numeric-3"
              >
                <strong>Shop!</strong>
              </v-stepper-step>
              <v-stepper-content step="0" class="text-left">
              </v-stepper-content>

              <v-stepper-step
                :complete="true"
                step="4"
                complete-icon="mdi-numeric-4"
              >
                When you’re ready to checkout, add a new payment method
              </v-stepper-step>
              <v-stepper-content step="0" class="text-left">
              </v-stepper-content>
              <v-stepper-step
                :complete="true"
                step="5"
                complete-icon="mdi-numeric-5"
              >
                Paste the card number that you copied earlier into the credit
                card form
              </v-stepper-step>

              <v-stepper-content step="0" class="text-left"></v-stepper-content>

              <v-stepper-step
                :complete="true"
                step="6"
                complete-icon="mdi-numeric-6"
              >
                Return to your Whistle wallet to do the same with the expiration
                date and 3 digit code
              </v-stepper-step>

              <v-stepper-content step="0" class="text-left"></v-stepper-content>

              <v-stepper-step
                :complete="true"
                step="6"
                complete-icon="mdi-numeric-7"
              >
                Complete your checkout!
              </v-stepper-step>

              <v-stepper-content step="0" class="text-left"></v-stepper-content>

              <v-stepper-step :complete="true" step="8">
                Wasn’t that easy? Be sure to save your card if given the option
                to make it even easier in the future!
              </v-stepper-step>

              <v-stepper-content step="1" class="py-1"> </v-stepper-content>
            </v-stepper>
          </v-card>

          <v-card
            elevation="0"
            rounded="0"
            v-else-if="walletMenuScreen == 'display-add-to-mobile'"
          >
            <!-- <v-card-title class="dialog-header" color="primary">
              <v-row class="">
                <v-col
                  class="my-0 py-0 text-left word-break"
                  cols="6"
                  sm="6"
                  md="6"
                >
                  Add to mobile wallet
                </v-col>
                <v-col
                  class="my-0 py-0 text-right cursor-pointer"
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <span @click="closeWallet"
                    >Close <v-icon color="white">mdi-close-circle-outline</v-icon></span
                  >
                </v-col>
              </v-row>
            </v-card-title> -->
            <v-toolbar dark color="brandCyan" elevation="0" rounded="0">
              <v-toolbar-title>Add to mobile wallet</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="closeWallet">
                  Close
                  <v-icon class="ml-2 mb-1">mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <div class="slide-group-button text-center mt-12 ml-6">
              <v-btn
                depressed
                small
                width="140"
                height="30"
                :color="activeTab == keys.ios ? 'white' : 'lightGrey'"
                class="mr-1"
                :class="{
                  'slide-button': activeTab == keys.ios
                }"
                @click="activeTab = keys.ios"
              >
                iOS
              </v-btn>
              <v-btn
                depressed
                small
                width="140"
                height="30"
                :color="activeTab == keys.android ? 'white' : 'lightGrey'"
                class="ml-1"
                :class="{
                  'slide-button': activeTab == keys.android
                }"
                @click="activeTab = keys.android"
              >
                Android
              </v-btn>
            </div>
            <div v-if="activeTab == keys.ios" key="0" class="mt-5">
              <v-stepper vertical class="elevation-0" v-model="defaultStepper">
                <v-stepper-step
                  :complete="true"
                  step="1"
                  complete-icon="mdi-numeric-1"
                >
                  Open the Wallet app on your iOS device
                </v-stepper-step>
                <v-stepper-content step="1"></v-stepper-content>
                <!-- <v-stepper-content step="1">
                <v-chip v-ripple outlined color="primary" label class="mr-4">
                  <v-avatar>
                    <v-icon class="mr-1">mdi-download</v-icon>
                  </v-avatar>
                  Download Template
                </v-chip> 
              </v-stepper-content> -->
                <v-stepper-step
                  :complete="true"
                  step="2"
                  complete-icon="mdi-numeric-2"
                >
                  Tap the + to add a new payment card
                </v-stepper-step>
                <v-stepper-content step="2">lll</v-stepper-content>

                <v-stepper-step
                  :complete="true"
                  step="3"
                  complete-icon="mdi-numeric-3"
                >
                  From the Add to Wallet menu, tap 'Debit or Credit Card'
                </v-stepper-step>
                <v-stepper-content step="0" class="text-left">
                  <small> Then click 'Continue'</small>
                </v-stepper-content>
                <!-- On Desktop -->
                <!-- <v-stepper-step
                  :complete="true"
                  step="4"
                  complete-icon="mdi-numeric-4"
                  v-if="!onMobile"
                >
                  Point your phone camera at the card displayed on the left of
                  this page
                </v-stepper-step>
                <v-stepper-content step="0" class="text-left" v-if="!onMobile">
                  <small>
                    This should populate your name and card number in the app<br />
                    If this doesn't work for you, you can tap "Enter card
                    details manually"</small
                  >
                </v-stepper-content> -->

                <!-- <v-stepper-step
                  :complete="true"
                  step="5"
                  complete-icon="mdi-numeric-6"
                  v-if="!onMobile"
                >
                  Enter the 3 digit code then tap ‘Next’
                </v-stepper-step> -->
                <v-stepper-content
                  step="0"
                  class="text-left"
                ></v-stepper-content>

                <!-- On Mobile -->
                <v-stepper-step
                  :complete="true"
                  step="4"
                  complete-icon="mdi-numeric-4"
                >
                  Point your phone camera at the card or tap 'Enter card details
                  manually' then copy the card number from this page and paste
                  it into the form
                </v-stepper-step>
                <v-stepper-content step="0" class="text-left">
                </v-stepper-content>

                <v-stepper-step
                  :complete="true"
                  step="5"
                  complete-icon="mdi-numeric-5"
                >
                  Tap 'Next' then do the same for expiration and cvv
                </v-stepper-step>
                <v-stepper-content
                  step="0"
                  class="text-left"
                ></v-stepper-content>

                <v-stepper-step
                  :complete="true"
                  step="6"
                  complete-icon="mdi-numeric-6"
                >
                  Tap 'Next' and agree to Issuer Terms
                </v-stepper-step>
                <v-stepper-content
                  step="0"
                  class="text-left"
                ></v-stepper-content>

                <v-stepper-step
                  :complete="true"
                  step="6"
                  complete-icon="mdi-numeric-7"
                >
                  Follow the verification steps
                </v-stepper-step>
                <v-stepper-content
                  step="0"
                  class="text-left"
                ></v-stepper-content>

                <v-stepper-step :complete="true" step="8">
                  Your card should now be set up in Apple Pay!
                </v-stepper-step>

                <v-stepper-content step="1" class="py-1"> </v-stepper-content>
              </v-stepper>
            </div>
            <div v-if="activeTab == keys.android" key="1" class="mt-5">
              <v-stepper vertical class="elevation-0" v-model="defaultStepper">
                <v-stepper-step
                  :complete="true"
                  step="1"
                  complete-icon="mdi-numeric-1"
                >
                  Open Google Wallet on your Andriod device
                </v-stepper-step>
                <v-stepper-content step="0" class="text-left">
                  <small>
                    If you don’t have the Google Wallet app, you’ll need to
                    download it from the Play store</small
                  >
                </v-stepper-content>
                <v-stepper-step
                  :complete="true"
                  step="2"
                  complete-icon="mdi-numeric-2"
                >
                  Tap "Add a card" to add a new payment card
                </v-stepper-step>
                <v-stepper-content step="2">lll</v-stepper-content>

                <v-stepper-step
                  :complete="true"
                  step="3"
                  complete-icon="mdi-numeric-3"
                >
                  Tap "+ New credit or debit card"
                </v-stepper-step>
                <v-stepper-content step="3"></v-stepper-content>

                <!-- If on desktop -->
                <!-- <v-stepper-step
                  :complete="true"
                  step="4"
                  complete-icon="mdi-numeric-4"
                  v-if="!onMobile"
                >
                  Point your phone camera at the card displayed on the left of
                  this page
                </v-stepper-step>
                <v-stepper-content step="0" class="text-left" v-if="!onMobile">
                  <small>
                    This should populate your name and card number in the app<br />
                    If this doesn't work for you, you can tap "Enter card
                    details manually"</small
                  >
                </v-stepper-content> -->
                <!-- 
                <v-stepper-step
                  :complete="true"
                  step="5"
                  complete-icon="mdi-numeric-5"
                  v-if="!onMobile"
                >
                  Enter the 3 digit code then tap ‘Next’
                </v-stepper-step>
                <v-stepper-content
                  step="0"
                  class="text-left"
                  v-if="!onMobile"
                ></v-stepper-content> -->

                <!-- If on mobile -->
                <v-stepper-step
                  :complete="true"
                  step="4"
                  complete-icon="mdi-numeric-4"
                >
                  Point your phone camera at the card or tap 'Enter details
                  manually' then copy the card number from this page and paste
                  it into Google Wallet
                </v-stepper-step>
                <v-stepper-content step="0" class="text-left">
                </v-stepper-content>

                <v-stepper-step
                  :complete="true"
                  step="5"
                  complete-icon="mdi-numeric-5"
                >
                  Do the same for expiration and CVC
                </v-stepper-step>
                <v-stepper-content
                  step="0"
                  class="text-left"
                ></v-stepper-content>

                <v-stepper-step
                  :complete="true"
                  step="6"
                  complete-icon="mdi-numeric-6"
                >
                  Fill in your billing address, then tap 'Save'
                </v-stepper-step>
                <v-stepper-content
                  step="6"
                  class="text-left"
                ></v-stepper-content>

                <v-stepper-step
                  :complete="true"
                  step="7"
                  complete-icon="mdi-numeric-7"
                >
                  Agree to the Issuer Terms
                </v-stepper-step>

                <v-stepper-content
                  step="7"
                  class="text-left"
                ></v-stepper-content>

                <v-stepper-step :complete="true" step="8">
                  Your card should now be set up in GPay!
                </v-stepper-step>

                <v-stepper-content step="1" class="py-1"> </v-stepper-content>
              </v-stepper>
            </div>
          </v-card>

          <v-card
            elevation="0"
            rounded="0"
            v-else-if="walletMenuScreen == 'display-save-instruction'"
          >
            <v-card-title class="dialog-header" color="primary">
              <v-row class="">
                <v-col class="my-0 py-0 text-left" cols="6" sm="6" md="6">
                  Save for later
                </v-col>
                <v-col
                  class="my-0 py-0 text-right cursor-pointer"
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <span @click="closeWallet"
                    >Close <v-icon color="white">mdi-close</v-icon></span
                  >
                </v-col>
              </v-row>
            </v-card-title>
            <v-stepper vertical class="elevation-0" v-model="defaultStepper">
              <v-stepper-step
                :complete="true"
                step="1"
                complete-icon="mdi-numeric-1"
              >
                You don’t have to spend your money today, it will be safe in
                your Whistle wallet and you can access it any time.
              </v-stepper-step>
              <v-stepper-content step="1"></v-stepper-content>
              <!-- <v-stepper-content step="1">
                <v-chip v-ripple outlined color="primary" label class="mr-4">
                  <v-avatar>
                    <v-icon class="mr-1">mdi-download</v-icon>
                  </v-avatar>
                  Download Template
                </v-chip> 
              </v-stepper-content> -->
              <v-stepper-step
                :complete="true"
                step="2"
                complete-icon="mdi-numeric-2"
              >
                It's totally fine to save up multipe payments, and then spend
                them all at once!
              </v-stepper-step>
            </v-stepper>
          </v-card>

          <v-card
            elevation="0"
            rounded="0"
            v-else-if="walletMenuScreen == 'display-virtual'"
          >
            <MarqetaDisplayCard
              v-if="walletMenuScreen == 'display-virtual' && displayWalletMenu"
              @get-cards="getCards"
              @reset="resetWalletScreen"
              @scrollToTop="scrollToTopOfWalletDrawer"
            />
          </v-card>

          <v-card
            elevation="0"
            rounded="0"
            v-else-if="walletMenuScreen == 'card-activation'"
          >
            <MarqetaActivateCard
              v-if="walletMenuScreen == 'card-activation' && displayWalletMenu"
              @get-cards="getCards"
              @reset="resetWalletScreen"
              @scrollToTop="scrollToTopOfWalletDrawer"
            />
          </v-card>
          <v-card
            elevation="0"
            rounded="0"
            v-else-if="walletMenuScreen == 'card-pin'"
          >
            <MarqetaCreatePIN
              v-if="walletMenuScreen == 'card-pin' && displayWalletMenu"
              @get-cards="getCards"
              @reset="resetWalletScreen"
              @scrollToTop="scrollToTopOfWalletDrawer"
            />
          </v-card>
          <v-card
            elevation="0"
            rounded="0"
            v-else-if="walletMenuScreen == 'update-user'"
          >
            <MarqetaEditUser
              v-if="walletMenuScreen == 'update-user' && displayWalletMenu"
              @reset="resetWalletScreen"
              @scrollToTop="scrollToTopOfWalletDrawer"
            />
          </v-card>
          <v-card
            elevation="0"
            rounded="0"
            v-else-if="walletMenuScreen == 'per-diem-report'"
          >
            <PerDiemReports
              v-if="walletMenuScreen == 'per-diem-report' && displayWalletMenu"
              @reset="resetWalletScreen"
              @scrollToTop="scrollToTopOfWalletDrawer"
              @get-wallet="refreshWallet"
            />
          </v-card>
          <v-card
            elevation="0"
            rounded="0"
            v-else-if="walletMenuScreen == 'simulate-transaction'"
          >
            <MarqetaSimulateTransaction
              v-if="
                walletMenuScreen == 'simulate-transaction' && displayWalletMenu
              "
              :cards="cards"
              @reset="resetWalletScreen"
              @scrollToTop="scrollToTopOfWalletDrawer"
            />
          </v-card>
          <v-card
            elevation="0"
            rounded="0"
            v-else-if="walletMenuScreen == 'donate'"
          >
            <StripeGivingWidget
              v-if="walletMenuScreen == 'donate' && displayWalletMenu"
              class="full-width"
              preloadMenu="CC"
              source="WALLET_GIVING"
              @reset="resetWalletScreen"
              @scrollToTop="scrollToTopOfWalletDrawer"
            />
          </v-card>
          <MarqetaFooter :column="true" />
        </v-navigation-drawer>

        <!-- End Wallet Modal -->

        <!-- Begin User Profile modal -->
        <v-navigation-drawer
          v-if="profileMenu"
          v-model="profileMenu"
          temporary
          fixed
          right
          width="500"
        >
          <v-card-title class="dialog-header" color="primary">
            <v-row class="">
              <v-col class="my-0 py-0 text-left" cols="6" sm="6" md="6">
                My Account
              </v-col>
              <v-col
                class="my-0 py-0 text-right cursor-pointer"
                cols="6"
                sm="6"
                md="6"
              >
                <span @click="profileMenu = false"
                  >Close <v-icon color="white">mdi-close</v-icon></span
                >
              </v-col>
            </v-row>
          </v-card-title>
          <v-row justify="center" class="py-14">
            <v-col cols="7" sm="7" md="7" lg="7">
              <v-img
                v-if="userProfile.profilePicUrl !== null"
                :src="userProfile.profilePicUrl"
                :aspect-ratio="1 / 1"
                :width="profileImageWidth"
                :height="profileImageHeight"
                class="circle-border-radius mx-auto profile-picture"
              />
              <v-icon
                v-else
                x-large
                class="profile-picture circle-border-radius icon-image"
                >mdi-account</v-icon
              >
              <h2 class="mt-4">{{ userProfile.displayName }}</h2>

              <v-list>
                <v-divider class="mt-4" />
                <v-list-item @click="loadProfile(0)" class="py-2">
                  <v-list-item-action-text
                    ><v-icon>mdi-account-edit</v-icon></v-list-item-action-text
                  >
                  <v-list-item-title class="text-left ml-3"
                    ><b>Profile</b></v-list-item-title
                  >
                </v-list-item>
                <v-divider />
                <v-list-item class="py-2" @click="loadProfile(2)">
                  <v-list-item-action-text
                    ><v-icon>mdi-star-circle</v-icon></v-list-item-action-text
                  >
                  <v-list-item-title class="text-left ml-3"
                    ><b>Badges</b></v-list-item-title
                  >
                </v-list-item>
                <!-- <v-divider />
                <v-list-item class="py-2" @click="loadProfile(1)">
                  <v-list-item-action-text
                    ><v-icon>mdi-cog-outline</v-icon></v-list-item-action-text
                  >
                  <v-list-item-title class="text-left ml-3"
                    ><b>Settings</b></v-list-item-title
                  >
                </v-list-item> -->
                <v-divider />
                <v-list-item class="py-2" @click="loadProfile(1)">
                  <v-list-item-action-text
                    ><v-icon
                      >mdi-message-text-outline</v-icon
                    ></v-list-item-action-text
                  >
                  <v-list-item-title class="text-left ml-3"
                    ><b>Communication</b></v-list-item-title
                  >
                </v-list-item>
                <!-- <v-divider />
                <v-list-item class="py-2" @click="loadProfile(3)">
                  <v-list-item-action-text
                    ><v-icon
                      >mdi-tablet-dashboard</v-icon
                    ></v-list-item-action-text
                  >
                  <v-list-item-title class="text-left ml-3"
                    ><b>Dashboard</b></v-list-item-title
                  >
                </v-list-item> -->
                <v-divider />
                <v-list-item class="py-2" @click="downloadTerms">
                  <v-list-item-action-text
                    ><v-icon>mdi-note-text</v-icon></v-list-item-action-text
                  >
                  <v-list-item-title class="text-left ml-3"
                    ><b>Terms and Conditions</b></v-list-item-title
                  >
                </v-list-item>
                <v-divider />
                <v-list-item class="py-2" @click="downloadPrivacyPolicy">
                  <v-list-item-action-text
                    ><v-icon>mdi-note-text</v-icon></v-list-item-action-text
                  >
                  <v-list-item-title class="text-left ml-3"
                    ><b>Privacy Policy</b></v-list-item-title
                  >
                </v-list-item>
                <v-divider />
                <v-list-item class="py-2" @click="gdprRequest">
                  <v-list-item-action-text
                    ><v-icon>mdi-email</v-icon></v-list-item-action-text
                  >
                  <v-list-item-title class="text-left ml-3"
                    ><b>GDPR Request</b></v-list-item-title
                  >
                </v-list-item>
                <v-divider />
                <!-- <v-list-item class="py-2">
                  <v-list-item-action-text
                    ><v-icon
                      >mdi-lightbulb-on-outline</v-icon
                    ></v-list-item-action-text
                  >
                  <v-list-item-title class="text-left ml-3"
                    ><b>Suggestions</b></v-list-item-title
                  >
                </v-list-item>
                <v-divider /> -->
                <v-list-item class="py-2" @click="openHelpCenter">
                  <v-list-item-action-text
                    ><v-icon
                      >mdi-help-circle-outline</v-icon
                    ></v-list-item-action-text
                  >
                  <v-list-item-title class="text-left ml-3"
                    ><b>Help Center</b></v-list-item-title
                  >
                </v-list-item>
                <v-divider />
                <v-list-item class="py-2" @click="logout">
                  <v-list-item-action-text class="hidden"
                    ><v-icon
                      >mdi-tablet-dashboard</v-icon
                    ></v-list-item-action-text
                  >
                  <v-list-item-title class="text-left ml-3"
                    ><b>Logout</b></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-navigation-drawer>
        <!-- GDPR Dialog window -->
        <v-dialog width="500" v-model="gdprDialog">
          <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
            <div class="d-flex justify-space-between align-center mx-6 mb-5">
              <v-icon color="error" x-large class="exit-warning-icon mr-2"
                >mdi-alert</v-icon
              >
              <v-card-title class="word-break text-left exit-warning-text">
                Do you have a GDPR data request?
              </v-card-title>
            </div>
            <span class="word-break exit-warning-text">
              Please contact our GDPR data officer at
              <a href="mailto:privacy@wewhistle.com?subject=GDPR Data Request"
                >privacy@wewhistle.com</a
              >
              for all requests. Please include your name and details on your
              request.
            </span>
            <v-card-actions class="mx-12">
              <v-btn
                color="primary"
                @click="gdprDialog = false"
                outlined
                width="130"
                >Close</v-btn
              >
              <v-spacer />
              <v-btn
                color="primary"
                href="mailto:privacy@wewhistle.com?subject=GDPR Data Request"
                width="130"
                >Email</v-btn
              >
            </v-card-actions>
          </v-card></v-dialog
        >

        <!-- End User Profile Modal -->
      </div>
      <!-- Otherwise, show loading screen -->
      <!-- <div
        v-else
        class="gradient-background d-flex flex-column justify-space-between"
      >
        <div class="d-flex align-start justify-center">
          <div
            class="content d-flex flex-row align-center justify-center flex-wrap"
          >
            <lottie
              :options="lottieOptions"
              :height="140"
              :width="140"
              v-on:animCreated="handleAnimation"
            />
            <div
              class="text mt-4 mx-auto"
              :class="{
                'text-left': !$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm,
              }"
            >
              <span>Setting up your account.</span>
              <br />
              <v-slide-y-transition>
                <span v-show="show">This should only take a few seconds.</span>
              </v-slide-y-transition>
            </div>
          </div>
        </div>
        <span
          >[loading: {{ $auth.loading ? "y" : "n" }}, auth:
          {{ $auth.isAuthenticated ? "y" : "n" }}, user: {{ $auth.user.sub }},
          email:
          {{
            userProfile && userProfile.businessEmail
              ? userProfile.businessEmail
              : "none"
          }}, token: {{ token ? token.length : "n" }}, perm:
          {{ permissions ? permissions.length : "n" }}, fail:
          {{ failedLogin ? "y" : "n" }}, err:
          {{ errorString ? errorString : "none" }}]</span
        >
      </div> -->
    </div>
  </v-app>
</template>

<script>
import navDrawer from "@/components/navDrawer.vue";
import navBar from "@/components/navBar.vue";
import AppStorePopup from "@/components/AppStorePopup.vue";
import LoginErrorPopup from "@/components/LoginErrorPopup.vue";
import MagicLinkExpirationPopup from "@/components/MagicLinkExpirationPopup.vue";
import Activation from "@/views/ActivationV2.vue";
import PaymentsActivation from "@/views/ActivationV3Payments.vue";
import ClientActivation from "@/views/ClientActivation.vue";
// import Home from "@/views/Home.vue";
// import EmailUnsubscribe from "@/views/EmailUnsubscribe.vue";
import MarqetaCardActivationSuccess from "@/views/MarqetaCardActivationSuccess.vue";
import MarqetaCardPINSuccess from "@/views/MarqetaCardPINSuccess.vue";
import MarqetaVirtualCardHosted from "@/views/MarqetaVirtualCardHosted.vue";
import MarqetaActivateCardHosted from "@/views/MarqetaActivateCardHosted.vue";
import MarqetaCreatePINHosted from "@/views/MarqetaCreatePINHosted";
import Login from "@/views/Login.vue";
import LoginMagicLink from "@/views/LoginMagicLink.vue";

// import SurveysPublic from "@/views/SurveysPublic.vue";
import { mapState } from "vuex";
import OnboardingService from "@/services/OnboardingService.js";
import MarqetaWidgetManager from "@/components/wallet/MarqetaWidgetManagerV2.vue";
import MarqetaDisplayCard from "@/components/wallet/MarqetaDisplayCard.vue";
import MarqetaActivateCard from "@/components/wallet/MarqetaActivateCard.vue";
import MarqetaCreatePIN from "@/components/wallet/MarqetaCreatePIN.vue";
import MarqetaEditUser from "@/components/wallet/MarqetaEditUser.vue";
import MarqetaSimulateTransaction from "@/components/MarqetaSimulateTransaction.vue";
import MarqetaFooter from "@/components/wallet/MarqetaFooter.vue";
import PerDiemReports from "@/components/PerDiemReports.vue";
import Robin from "@/components/Robin.vue";
import StripeGivingWidget from "@/components/stripe/StripeGivingWidget.vue";

import { truncateNumber, numberWithCommas } from "@/shared_data/functions";

import Lottie from "vue-lottie/src/lottie.vue";
import * as animationData from "@/assets/robin/Eating.json";

// Imports Quill styling so any html text with custom classes gets rendered properly
import "quill/dist/quill.core.css";
// import Container from "./components/vertex-ai/Container.vue";
import HelpWidget from "./components/main/HelpWidget.vue";
// import ICountUp from "@/components/ICountUp";
// import countTo from "vue-count-to";
// import moment from "moment";

export default {
  title: "Whistle",
  components: {
    HelpWidget,
    navDrawer,
    navBar,
    AppStorePopup,
    LoginErrorPopup,
    MagicLinkExpirationPopup,
    Activation,
    PaymentsActivation,
    ClientActivation,
    // Home,
    Login,
    LoginMagicLink,
    Lottie,
    Robin,
    StripeGivingWidget,
    // ICountUp,
    // EmailUnsubscribe,
    MarqetaCardActivationSuccess,
    MarqetaCardPINSuccess,
    MarqetaVirtualCardHosted,
    MarqetaActivateCardHosted,
    MarqetaCreatePINHosted,
    MarqetaWidgetManager,
    // SurveysPublic,
    // countTo,
    // MarqetaCardRequest,
    // MarqetaBusinessCreation,
    MarqetaDisplayCard,
    MarqetaActivateCard,
    MarqetaCreatePIN,
    MarqetaEditUser,
    MarqetaFooter,
    MarqetaSimulateTransaction,
    PerDiemReports
  },

  created() {
    if (!this.$auth.isAuthenticated) this.loading = true;

    // if (this.$auth.isAuthenticated && !this.userProfile) {
    //   this.$auth.logout({
    //     returnTo: window.location.origin,
    //   });
    // }
    // this.unwatch = this.$store.watch(
    //   (state) => {
    //     return state.failedLogin; // could also put a Getter here
    //   },
    //   (newValue, oldValue) => {
    //     console.log(`Updating from ${oldValue} to ${newValue}`);
    //     if (newValue) {
    //       this.$auth.logout({
    //         returnTo: window.location.origin,
    //       });
    //     }
    //   },
    //   //Optional Deep if you need it
    //   {
    //     deep: true,
    //   }
    // );

    //When loading, show second message after 3 seconds
    setTimeout(() => (this.show = true), 3000);

    //If Auth0 is still loading after 15 seconds then we show a popup
    setTimeout(() => {
      if (
        !this.showLogin &&
        !this.showMagicLinkLogin &&
        (!this.$auth || this.$auth.loading || this.$auth.errorType) &&
        !this.failedLogin &&
        !this.showClientBlacklist &&
        !this.magicLinkToken
      )
        this.showLoginError = true;
      else console.log("Not showing login error");
    }, 15000);
    const hasChatbotScope = (this.permissions || [])?.includes(
      "vue:read:chatbot"
    );
    if (!hasChatbotScope) {
      //Freshworks support script tag
      let freshWorksScript = document.createElement("script");
      freshWorksScript.setAttribute(
        "src",
        "https://widget.freshworks.com/widgets/69000001278.js"
      );

      document.head.appendChild(freshWorksScript);
      window.fwSettings = { widget_id: 69000001278 };
      !(function() {
        if ("function" != typeof window.FreshworksWidget) {
          var n = function() {
            n.q.push(arguments);
          };
          (n.q = []), (window.FreshworksWidget = n);
        }
      })();
    }
    // Checks if we're on a mobile device and if the user already closed the mobile dialog
    if (
      !this.postItArray.includes("mobile-1") &&
      this.$isMobile() &&
      this.userProfile &&
      this.userProfile.Client &&
      this.userProfile.Client.displayMobilePopup &&
      this.$route.name != "giving" &&
      this.$route.name != "walletappclip"
    ) {
      this.showMobileModal = true;
    }

    if (this.$isMobile()) {
      this.onMobile = true;
    }

    // If the page loads and the wallet is open, refresh balance
    if (this.displayWalletMenu == true) {
      // this.walletToggler();
      this.displayWalletMenu = false;
    }
  },
  beforeDestroy() {
    // this.unwatch();
  },
  data() {
    return {
      help: {
        direction: "top",
        fab: false,
        fling: false,
        hover: false,
        tabs: null,
        top: false,
        right: true,
        bottom: true,
        left: false,
        transition: "slide-y-reverse-transition"
      },
      loading: false,
      show: false,
      showLoginError: false,
      profileMenu: false,
      lottieOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true
      },
      anim: null,
      animationSpeed: 1,
      showMobileNavDrawer: false,
      wallet: {
        showBusinessCreationForm: false,
        showPhysicalCardForm: false,
        showVirtualCardForm: false,
        showCardActivationForm: false,
        showVirtualCardInfo: false,
        showCardTokenization: false,
        showUserEditWidget: false
      },
      defaultStepper: 0,
      cards: [],
      canRegister: false,
      alreadyRegistered: false,

      /* move these to wallet component */
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: ""
      },
      delay: 0,
      startVal: 0,
      endVal: 0,
      // loadingBalance: false,
      search: "",
      gdprDialog: false,
      activeTab: 0,
      onMobile: false,
      keys: {
        ios: 0,
        android: 1
      }
    };
  },
  mounted() {},
  methods: {
    truncateNum(amount, precision) {
      return truncateNumber(amount, precision);
    },
    formatCurrency(amount) {
      return numberWithCommas(amount);
    },
    toggleProfile() {
      this.profileMenu = !this.profileMenu;
    },
    handleAnimation: function(anim) {
      this.anim = anim;
    },
    closeWallet() {
      this.$store.dispatch("setDisplayWalletMenu", false);
    },
    closeAppStorePopup() {
      this.showMobileModal = false;
      if (!this.postItArray.find(x => x == "mobile-1"))
        this.$store.dispatch("setPostItArray", "mobile-1");
    },
    refreshWallet() {
      this.$store.dispatch("refreshWallet");
      // var oldBalance = this.cashBalance;
      // console.log("Old balance ", oldBalance);
      // this.loadingBalance = true;
      // this.startVal = parseFloat(oldBalance);
      // this.$store.dispatch("setUser", this.userProfile.userId).then(() => {
      //   console.log("DONE SETTING USER");
      //   console.log("Got new balance: ", this.cashBalance);
      //   this.endVal = this.truncateNum(parseFloat(this.cashBalance), 2);
      //   this.loadingBalance = false;
      //   // var thisCountUp = this.$refs.balanceCount;
      //   // console.log(thisCountUp);
      //   // thisCountUp.update(this.endVal);
      //   // thisCountUp.reset();
      //   // thisCountUp.start();

      //   //no conflicts here brah
      // });
    },
    walletToggler: function() {
      console.log("toggler called in app.vue");
      this.$store.dispatch("setDisplayWalletMenu", true);
      this.walletScreen = "main";
      this.$store.dispatch("setSourceWalletPage", null);

      // if (this.displayWalletMenu == true) {
      //   // this.getCards();
      //   this.refreshWallet();
      // }
    },
    onReady: function(instance) {
      //const that = this;
      //instance.update(that.endVal + 100);
      //instance.reset();
      console.log(instance);
    },
    login() {
      this.$auth.loginWithEmbedded({
        connection: process.env.VUE_APP_ENVIRONMENT
      });
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    refresh() {
      document.location.reload();
    },
    loadProfile(tab) {
      // We have to check if we're already on the router. If so, we just close the profile
      if (this.$route.name == "profile") {
        // We close the wallet
        this.closeWallet();
        // We also update the profile tab
        this.$store.dispatch("setProfilePageTab", tab);

        this.profileMenu = false;
      } else {
        this.$router.push({
          name: "profile",
          params: { initialPageIndexToLoad: tab }
        });
      }
    },
    loadWallet(tab) {
      // We have to check if we're already on the router. If so, we just close the profile
      if (this.$route.name == "wallet") {
        // We close the wallet
        this.closeWallet();
      } else {
        this.$router.push({
          name: "wallet",
          params: { initialPageIndexToLoad: tab }
        });
      }
    },
    downloadTerms() {
      OnboardingService.downloadLegalDoc("terms");
    },
    downloadPrivacyPolicy() {
      OnboardingService.downloadLegalDoc("privacy");
    },
    gdprRequest() {
      this.gdprDialog = true;
    },
    openHelpCenter() {
      // Opens up FreskDesk in a new tab
      window.open("https://whistle.freshdesk.com/support/home", "_blank");
    },
    toggleMobileNav() {
      console.log("Success");
      // This is called only for the mobile nav drawer
      this.showMobileNavDrawer = true;
    },
    scrollToTopOfWalletDrawer() {
      var navDrawerContent = this.$refs["walletNavDrawer"].$el.querySelector(
        "div.v-navigation-drawer__content"
      );
      navDrawerContent.scrollTop = 0;
    },
    getCards() {
      // We fetch their current cards and hide things based on state
      this.$store.dispatch("setMarqeta");
    },
    resetWalletScreen() {
      if (
        this.sourceWalletPage == "wallet" ||
        this.sourceWalletPage == "programs"
      ) {
        this.displayWalletMenu = false;
        // We have to set the source page so we know when they click back, it closes the drawer
        this.$store.dispatch("setSourceWalletPage", null);
        this.walletScreen = "main";
      } else {
        this.walletScreen = "main";
      }
    },
    showMobileWalletInstructions() {
      if (this.hasVirtualCard) {
        this.showVirtualCard = true;
        this.scrollToTop();
      }
      // If we're on the wallet screen and the wallet drawer isn't open, then we open it
      if (this.$route.name == "wallet" && !this.displayWalletMenu) {
        this.$store.dispatch("setDisplayWalletMenu", true);
        // We have to set the source page so we know when they click back, it closes the drawer
        this.$store.dispatch("setSourceWalletPage", "wallet");
      }
      //this.scrollToTopOfWalletDrawer();
      //this.walletScreen = "display-mobile-wallet-instruction";
      this.$store.dispatch("setWalletMenuScreen", "display-add-to-mobile");
    }
  },
  computed: {
    ...mapState([
      "userProfile",
      "failedLogin",
      "errorString",
      "token",
      "cashBalance",
      // "pendingBalance",
      "permissions",
      "postItArray",
      "walletMenuScreen",
      "sourceWalletPage",
      "displayMobilePopup",
      "magicLinkToken",
      "magicLinkTokenExpirationWarning"
    ]),
    showMobileModal: {
      get: function() {
        console.log("computed modal");
        return this.displayMobilePopup;
      },
      set: function(newVal) {
        this.$store.dispatch("setDisplayMobilePopup", newVal);
      }
    },
    showMagicLinkWarningModal: {
      get: function() {
        return this.magicLinkTokenExpirationWarning;
      },
      set: function(newVal) {
        this.$store.dispatch("setMagicLinkTokenExpirationWarning", newVal);
      }
    },
    displayNavigationDrawer() {
      if (this.magicLinkToken) return false;
      return ![
        "login",
        "spirit-login",
        "generic-3pa-login",
        "emailunsubscribe",
        "surveyPublic",
        "walletactivationsuccess",
        "walletpinsuccess",
        "marqetavirtualcardhosted",
        "marqetaactivatecardhosted",
        "marqetacreatepinhosted",
        "setup",
        "slacksetup",
        "user-signup",
        "user-signup-static",
        "login-error",
        "procore",
        "peoplefilewizard"
      ].includes(this.$route.name);
    },
    displayNavigationBar() {
      return ![
        "login",
        "spirit-login",
        "generic-3pa-login",
        "emailunsubscribe",
        "surveyPublic",
        "walletactivationsuccess",
        "walletpinsuccess",
        "marqetavirtualcardhosted",
        "marqetaactivatecardhosted",
        "marqetacreatepinhosted",
        "setup",
        "slacksetup",
        "user-signup",
        "user-signup-static",
        "login-error",
        "procore",
        "peoplefilewizard"
      ].includes(this.$route.name);
    },
    displayWalletMenu: {
      get() {
        return this.$store.state.displayWalletMenu;
      },
      set(state) {
        if (state !== this.$store.state.displayWalletMenu) {
          this.$store.dispatch("setDisplayWalletMenu", state);
        }
      }
    },

    walletScreen: {
      get: function() {
        return this.walletMenuScreen;
      },
      set: function(newValue) {
        this.$store.dispatch("setWalletMenuScreen", newValue);
      }
    },
    unsubscribing() {
      if (this.$route.name == "emailunsubscribe") {
        return true;
      }
      return false;
    },
    showLogin() {
      return (
        !this.$auth.loading &&
        (this.$route.name == "login" ||
          this.$route.name == "spirit-login" ||
          this.$route.name == "generic-3pa-login")
      );
    },
    showMagicLinkLogin() {
      return this.$route.name == "magic-link";
    },
    showCardActivationSuccess() {
      if (this.$route.name == "walletactivationsuccess") {
        return true;
      }
      return false;
    },
    showCardPINSuccess() {
      if (this.$route.name == "walletpinsuccess") {
        return true;
      }
      return false;
    },
    showHostedVirtualCard() {
      if (this.$route.name == "marqetavirtualcardhosted") {
        return true;
      }
      return false;
    },
    showHostedActivateCard() {
      if (this.$route.name == "marqetaactivatecardhosted") {
        console.log("activate card");
        return true;
      }
      return false;
    },
    showHostedCardPIN() {
      if (this.$route.name == "marqetacreatepinhosted") {
        console.log("Create PIN for card");
        return true;
      }
      return false;
    },
    showClientBlacklist() {
      return (
        this.$auth.isAuthenticated &&
        this.userProfile &&
        this.userProfile.Client &&
        this.userProfile.Client.onboardingStatus == "BLACKLIST"
      );
    },
    showClientSetupUnauthenticated() {
      if (
        (this.$route.name == "setup" || this.$route.name == "slacksetup") &&
        !this.$auth.isAuthenticated &&
        !this.loading
      ) {
        return true;
      }
      return false;
    },
    showClientSetup() {
      if (
        // Either have to be manually routing to the page
        this.$route.name == "setup" ||
        this.$route.name == "slacksetup" ||
        // Or the user is a client admin and the process hasn't been finished yet
        (this.$auth.isAuthenticated &&
          this.userProfile &&
          this.userProfile.clientAdmin &&
          this.userProfile.Client &&
          this.userProfile.Client.onboardingStatus !== null &&
          this.userProfile.Client.onboardingStatus != "COMPLETE")
      ) {
        return true;
      }
      return false;
    },
    publicSurvey() {
      if (this.$route.name == "surveyPublic") {
        return true;
      }
      return false;
    },
    showPaymentsActivation() {
      return (
        this.userProfile &&
        this.userProfile.firstLogin &&
        this.userProfile.Client &&
        this.userProfile.Client.onboardingLanguage &&
        this.userProfile.Client.onboardingLanguage
          .toLowerCase()
          .includes("payment")
      );
      // return true;
    },
    profileImageHeight() {
      var size = this.$vuetify.breakpoint.name;
      if (size == "xs") {
        return 100;
      } else if (size == "sm") {
        return 120;
      } else {
        return 150;
      }
    },
    profileImageWidth() {
      var size = this.$vuetify.breakpoint.name;
      if (size == "xs") {
        return 100;
      } else if (size == "sm") {
        return 120;
      } else {
        return 150;
      }
    },
    // computedBalance() {
    //   //var bal = this.endVal;
    //   var bal = parseFloat(this.endVal);
    //   console.log("balance:::" + bal);

    //   if (this.userProfile && this.endVal != 0) {
    //     var provisional = this.userProfile.PaymentRules.filter(
    //       (x) => x.displayBalanceInWallet
    //     );
    //     provisional.forEach((rule) => {
    //       console.log(rule);
    //       console.log(bal + "-" + rule.balance);
    //       bal = bal - rule.balance;
    //       console.log(bal);
    //     });
    //   }
    //   //Subtraction can cause lots of floating point problems of 0.99999999 and toFixed rounds up, so I hack a solution here
    //   console.log("balance:::" + bal);
    //   return this.formatCurrency(
    //     this.truncateNum(parseFloat(bal).toFixed(3), 2)
    //   );
    // },
    computedBalance: {
      cache: false,
      get: function() {
        var bal = this.truncateNum(parseFloat(this.cashBalance), 2);

        //Subtraction can cause lots of floating point problems of 0.99999999 and toFixed rounds up, so I hack a solution here
        return this.formatCurrency(
          this.truncateNum(parseFloat(bal).toFixed(3), 2)
        );
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    }
  },
  watch: {
    "$auth.loading": function(newVal) {
      /**
       * We add a watcher for auth0 loading because for whatever reason
       * if you use Safari, authGuard would get loaded by the router-link component a split second before the watcher component for auth0.loading
       * So my solution was to sleep so the watcher in authGuard would run first.
       */
      if (!newVal)
        setTimeout(() => {
          this.loading = false;
          this.showLoginError = false;
        }, 10);

      // If we're done loading, no failed login, and the user isn't on login-error, then we wipe the path
      if (!this.failedLogin && this.$route.name == "login-error")
        this.$router.push("/");
    },
    "$auth.errorType": function(newVal) {
      console.log("New value for error type! ", newVal);
      this.showLoginError = true;
    }
  }
};
</script>

<style>
@font-face {
  font-family: "Avenir";
  src: local("Avenir"), url(./fonts/AvenirLTStd-Book.otf) format("truetype");
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  background-color: var(--v-lightGrey-base);
}
/* .v-application--wrap {
  margin-bottom: -89px;
} */
.gradient-background {
  /* background-color: white; */
  /* background: linear-gradient(to bottom, white, 80%, var(--v-brandGreen-base)); */
  background: linear-gradient(
      45deg,
      rgba(161, 215, 110, 1) 1%,
      rgba(10, 172, 235, 1) 100%
    ),
    white;
  background-size: 100% 100%, 100%;
  background-repeat: no-repeat;
  background-position: bottom, top;
  width: 100%;
  height: 100%;
}
.white-background {
  background-color: white;
}
.primary-background {
  background-color: var(--v-primary-base);
}
.brand-background {
  background-color: var(--v-brand-base);
}
.light-grey-background {
  background-color: var(--v-lightGrey-base);
}
.medium-grey-background {
  background-color: var(--v-mediumGrey-base);
}
.brand-green-background {
  background-color: var(--v-brandGreen-base);
}
.brand-light-green-background {
  background-color: var(--v-brandLightGreen-base);
}
.brand-dark-green-background {
  background-color: var(--v-brandDarkGreen-base);
}
.brand-cyan-background {
  background-color: var(--v-brandCyan-base);
}
.brand-orange-background {
  background-color: var(--v-brandOrange-base);
}
.accent-background {
  background-color: var(--v-accent-base);
}
.accent-dark-background {
  background-color: var(--v-accentDark-base);
}
.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}
.full-screen-width {
  width: 100vw;
}
.full-screen-height {
  min-height: 100vh;
}
.main-content-top {
  padding-top: 10px;
  padding-bottom: 10px;
}
.main-content-sides {
  padding-left: 10px;
  padding-right: 10px;
}
/* .v-main__wrap { */
.pad {
  padding: 10px;
}
.align-text-left {
  text-align: left;
}
.align-text-right {
  text-align: right;
}
.align-text-center {
  text-align: center;
}
.hidden {
  visibility: hidden;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-grab {
  cursor: grab;
}
.cursor-wait {
  cursor: wait;
}
.selectable-blue-text {
  color: #3a6ed5;
}
.v-data-table-header {
  background-color: var(--v-lightGrey-base);
}

.v-dialog--fullscreen .max-height.v-card.v-sheet {
  overflow: scroll;
}

.dialog-header {
  background-color: var(--v-brandCyan-base);
  color: white;
  /* padding-bottom: 5%; */
  height: 70px;
}
.word-break {
  word-break: break-word;
}
.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}
.circle-border-radius {
  border-radius: 100%;
}

.mq-notification {
  z-index: 999 !important;
  margin-left: 75px !important;
}

/* Used for html timestamp selector. This will match Vue styling */
.timestamp-input {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  line-height: 20px;
  padding: 8px 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 16px 0px;
  min-width: 235px;
  /* color: rgba(0, 0, 0, 0.38); */
}

/* .v-dialog__content--active {
  position: absolute;
  min-height: 100%;
}

.v-dialog--fullscreen {
  position: absolute;
  overflow-y: visible;
} */

/* Used to clip the text with ellipsis */
.text-overflow-clip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* Used to wrap text to the next line */
.text-overflow-wrap {
  overflow-wrap: break-word;
  white-space: initial;
}

.whistle-gradient {
  background: rgb(162, 215, 109);
  background: linear-gradient(0deg, rgba(162, 215, 109, 1) 0%, #09acec);
}

.whistle-gradient-45 {
  background: linear-gradient(
      45deg,
      rgba(161, 215, 110, 1) 1%,
      rgba(10, 172, 235, 1) 100%
    ),
    white;
  background-size: 100% 100%, 100%;
  background-repeat: no-repeat;
  background-position: bottom, top;
}

.ql-container {
  word-break: break-word;
}

.slide-group-button {
  padding: 5px 5px;
  border-radius: 7px;
  width: 300px;
  background-color: var(--v-lightGrey-base);
}

button.auth-type.v-btn .v-btn__content {
  justify-content: start !important;
}

.vjs-custom-skin .video-js {
  background: #ffffff !important;
}
.white-table-headers {
  /* border: solid 1px;
  border-color: lightgray; */
  border-radius: 20px;
  /* border-radius: 0px; */
}
.white-table-headers .v-data-table__wrapper {
  border-radius: 20px 0 0 0;
  padding-top: 10px;
}
.white-table-headers .v-data-table-header {
  background-color: white;
}

/* Used by the popup for recognitions */
.recognition-popup-dialog {
  height: 100%;
  overflow: hidden;
}

/* Styling for box with preset in account management and recognition wizard */
.preset-selector-box {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  justify-content: start;
  align-items: center;
  border: 1px solid var(--v-brandDarkGreen-base) !important;
  margin-left: auto;
  margin-right: auto;
}
.preset-selector-box:hover {
  background-color: #eeeeee;
}
.preset-selector-emoji {
  font-size: 40px;
  margin-right: 36px;
}

.box-border-global {
  border: solid 1px;
  border-color: lightgray;
  border-radius: 0;
}
.ellipsis-fill {
  flex-shrink: 0 !important;
  flex-grow: 1 !important;
  border-bottom: dotted black 1px;
  transform: translateY(-7px);
}

.text-xs {
  font-size: x-small;
}
.text-sm {
  font-size: small;
}
.text-md {
  font-size: medium;
}
.text-lg {
  font-size: large;
}
.text-xl {
  font-size: x-large;
}

.clickthrough {
  pointer-events: none;
}
.clickthrough > * {
  pointer-events: all;
}
.v-badge.nudge-up * {
  transform: translate(-6px, -6px);
}
</style>

<style scoped>
.text {
  color: grey;
  font-size: medium;
  min-width: 300px;
}
.content {
  margin-top: 15vh;
}
.wallet-icon {
  background: #549c2d;
  color: #fff;
  padding: 6px;
  border-radius: 4px;
  font-size: 1.3em;
}

.wallet-nav .v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
}

.balance {
  font-size: 1.35em;
}
.balance.in-process {
  color: #999;
}

.slide-button.white:hover {
  background-color: #ffffff !important;
}

.white.v-btn:before {
  background-color: #ffffff !important;
}

@media (max-width: 2000px) {
  .robin {
    width: 100px;
    height: auto;
  }
  .text {
    font-size: large;
  }
}
@media (min-width: 2001px) {
  .robin {
    width: 150px;
    height: auto;
  }
  .text {
    font-size: x-large;
  }
}

.profile-picture {
  border: 2px solid;
  border-color: var(--v-grey-base);
}
.icon-image {
  height: 150px;
  width: 150px;
}

/* Hides gray background when clicking v-card */
.wallet-nav >>> .v-card--link:before {
  background: none;
}
</style>
